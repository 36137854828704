@import url('https://fonts.googleapis.com/css?family=Prompt:400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Prompt');

:root {
  --primary-color: #031d78;
  --background-color: #f9fafe;
  --black-color: #000000;
  --grey-color: #6c6c6c;
  --white-color: #ffffff;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  .card-container {
    padding: 16px !important;
  }
}

.background-color {
  background-color: var(--background-color);
}

.text-primary-color {
  color: var(--primary-color);
}

.text-black-color {
  color: var(--black-color);
}

.text-grey-color {
  color: var(--grey-color);
}

.text-semi24 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'Prompt';
}

.text-semi16 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Prompt';
}

.text-body16 {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Prompt';
}

.text-link16 {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Prompt';
  text-decoration: underline;
  cursor: pointer;
}

.card-container {
  background-color: var(--white-color);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  box-shadow: 0px 4px 8px #00000008;
}

.card-section {
  background-color: var(--background-color);
  border-radius: 4px;
  padding: 16px;
  width: 100%;
}

.prompt-font {
  font-family: 'Prompt';
}
